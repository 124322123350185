import { useRouter } from "next/router";
import { useEffect } from "react";
import { NotFoundPage } from "screens/error";

const NotFound = () => {
    const router = useRouter();
    useEffect(() => {
        router.push('/404')
    }, [])
    return <NotFoundPage></NotFoundPage>
}
export default NotFound;