import { Box, styled } from '@mui/material';

export const ErrorBox = styled(Box)(({ theme }) => ({
  position: 'relative',
 // background: 'linear-gradient(179deg, #345BFF -13%, #090B12 97%)',
  minHeight: '100vh',
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  overflow: 'hidden',
  '.MuiContainer-root': {
    zIndex: '1'
  },
  '.cs-watermark': {
    position: 'absolute',
    left: '50%',
    top: '50%',
    transform: 'translate(-50%, -50%)',
    opacity: '0.03',
    'svg': {
      transform: 'scale(20)'
    }
  },
  '.cs-logo': {
    textAlign: 'center',
    position: 'relative',
    paddingBottom: theme.spacing(3),
    marginBottom: theme.spacing(3),
    '&::before': {
      content: `''`,
      position: 'absolute',
      width: '3.125rem',
      height: '0.125rem',
      backgroundColor: theme.colors.white,
      bottom: '0',
      left: '50%',
      transform: 'translate(-50%, -50%)',
    },
    "svg":{
      width: '100%'
    }
  },
  '.cs-content': {
    textAlign: 'center',
    '.cs-error-title': {
      display: 'inline-block',
      color: '#283676',
      marginBottom: theme.spacing(3),
      fontSize: '10rem',
      lineHeight: '11.1rem',
      [theme.breakpoints.down('lg')]: {
        padding: `${theme.spacing(0)} ${theme.spacing(1)}`,
        fontSize: '8rem',
        lineHeight: '8rem',
        marginBottom: theme.spacing(2),
      },
    },
    '.cs-details': {
      padding: `${theme.spacing(0)} ${theme.spacing(23.75)}`,
      color: '#283676',
      marginBottom: theme.spacing(2),
      [theme.breakpoints.down('lg')]: {
        padding: `${theme.spacing(0)} ${theme.spacing(1)}`,
        fontSize: '1.03rem'
      },
    },
  },
  '.cs-user-details': {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    marginTop: theme.spacing(3),
    '.cs-user': {
      margin: `${theme.spacing(0)} ${theme.spacing(2)}`,
      color: '#283676',
      fontWeight: '500',
      fontSize: '1.1rem'
    },
    '.cs-user-title': {
      color: '#283676',
    },
  }
}));