import { Box, Typography, Container, Grid, Button } from '@mui/material';
import { Logo } from 'ui/shared/logo';
import { ErrorBox } from './NotFoundPage.styled';
import { ArrowRightOutlineIcon } from 'ui/icons';
import { useRouter } from 'next/router';
import Head from 'next/head';

export const NotFoundPage = () => {
  const router = useRouter();
  const backToHome = () => {
    router.push('/')
  }
  return (
    <ErrorBox>
      <Head>
        <meta name="robots" content="noindex,nofollow"></meta>
      </Head>
      <Container component="main" maxWidth="xl">
        <Grid item xs={12}>
          <Box className="cs-logo">
            <Logo />
          </Box>
          <Box className="cs-content">
            <Typography variant="h1" className="cs-error-title">404</Typography>
            <Typography variant="body1" className="cs-details">
              Page Not Found!
            </Typography>
          </Box>
        </Grid>
        <Grid container columnSpacing={3}>
          <Grid item md={12} xs={12}>

            <Box className="center-btn">
              <Button
                color="primary"
                className="banner-btn"
                id="ii-404"
                onClick={backToHome}
              >
                Back To Home <ArrowRightOutlineIcon />
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Container>

    </ErrorBox>
  );
};
